@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./assets/icons/_materialdesignicons.scss";
@import "./variables.css";
@import "./materialize-fix.css";

body {
  font-family: "Roboto", sans-serif;
  background-color: #f0f2f5;
  height: 0 !important;
}

.center-text {
  text-align: center;
}

.modal {
  border-radius: 15px !important;
}

.full-width {
  width: 100%;
}

.rounded-card {
  border-radius: 10px !important;
}

.userPhoto {
  object-fit: cover;
}

.loading>*>.backdrop {
  z-index: 1999;
  opacity: 19 !important;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: #0000007d !important;
  width: 100% !important;
  visibility: visible !important;
}

.custom-class {
  top: 0;
  z-index: 2002;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -55px
}

.custom-class p {
  color: white;
  font-size: 18px;
}

span.field-icon {
  float: right;
  position: absolute;
  right: 18px;
  top: 7px;
  font-size: 22px;
  color: #5a5a5a;
  cursor: pointer;
  z-index: 2;
}

.swal2-popup.swal2-modal.swal2-show {
  padding: 10px 16px !important;
}

input.swal2-input {
  margin-top: 18px;
}

.btn .mdi {
  font-size: 16px;
}

.btn-service {
  text-transform: none !important;
}

.btn-success {
  text-decoration: none;
  text-transform: none !important;
  color: #fff;
  background-color: #26a69a !important;
  white-space: nowrap;
}

.btn-error {
  text-decoration: none;
  text-transform: none !important;
  color: #fff;
  background-color: #e54f4f !important;
  white-space: nowrap;
}

/* h2#swal2-title {
  font-size: 22px;
} */
