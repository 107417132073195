nav.nav-extended .nav-wrapper {
  min-height: 40px !important;
  height: 40px !important;
}

.table-error td, .table-error th {
  padding: 5px 5px !important;
}

.card {
  position: relative;
  margin: 0px 0 10px 0 !important;
  background-color: #fff;
  transition: box-shadow .25s;
  border-radius: 2px;
  padding: 10px;
}

.datepicker-date-display,
.datepicker-table td.is-selected,
.timepicker-digital-display {
  background-color: var(--primary-color) !important;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done,
.timepicker-close,
.input-field .prefix.active,
.dropdown-content li>a,
.dropdown-content li>span {
  color: var(--primary-color) !important;
}

.timepicker-svg>*>line {
  stroke: #5d8fad !important;
}

.timepicker-canvas-bg,
.timepicker-canvas-bearing {
  fill: #5d8fad !important;
}

.timepicker-tick.active,
.timepicker-tick:hover {
  background-color: #2a485a40 !important;
}

.input-field .prefix~input,
.input-field .prefix~textarea,
.input-field .prefix~label,
.input-field .prefix~.validate~label,
.input-field .prefix~.helper-text,
.input-field .prefix~.error-text,
.input-field .prefix~.autocomplete-content {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.input-field .error-text {
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 12px;
  color: #F44336;
}

.invalid input,
input.invalid {
  border-bottom: 1px solid #F44336 !important;
  box-shadow: none !important;
}

input:focus {
  border-bottom: 1px solid #26a69a;
  box-shadow: none !important
}

.card .card-content {
  padding: 10px !important;
  border-radius: 0 0 2px 2px;
}